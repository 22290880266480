import React from 'react'

export default {
  sections: [
    {
      title: '個人情報について',
      chapters: [
        {
          title: '個人情報の取得と利用目的',
          children: () => (
            <p>
              当サイトでは、メールでのお問い合わせなどの際に名前、メールアドレス等の個人情報をご登録いただく場合がございます。<br /><br />
              これらの個人情報は質問に対する回答や必要な情報を電子メールなどでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
            </p>
          )
        },
        {
          title: '個人情報の第三者への開示',
          children: () => (
            <>
              <p>
                当サイトでは、個人情報は適切に管理し以下に該当する場合を除いて第三者に開示することはありません。
              </p >
              <ul>
                <li>本人の了解がある場合</li>
                <li>法令等への協力のため、開示が必要となる場合</li>
              </ul>
            </>
          )
        },
        {
          title: '個人情報の開示、訂正、追加、削除、利用停止',
          children: () => (
            <p>
              ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
            </p>
          )
        }
      ]
    },
    {
      title: 'アクセス解析ツールについて',
      children: () => (
        <p>
          当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br /><br />
          このGoogleアナリティクスはアクセス情報の収集のためにCookieを使用しています。このアクセス情報は匿名で収集されており、個人を特定するものではありません。<br /><br />
          GoogleアナリティクスのCookieの保持期間は26ヶ月間に設定されています。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定でご確認ください。<br /><br />
          詳しくは、<a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" rel="noopener noreferrer">こちら</a>、または<a href="https://policies.google.com/technologies/partner-sites?hl=ja" target="_blank" rel="noopener noreferrer">こちら</a>をクリックしてご確認ください。
        </p >
      )
    },
    {
      title: '免責事項',
      children: () => (
        <p>
          当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。権利を侵害する目的はございません。記事の内容や掲載画像等に問題がございましたら、各権利所有者様本人が直接メールでご連絡下さい。確認後、対応させて頂きます。<br /><br />
          当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等については一切の責任を負いません。<br /><br />
          当サイトに掲載された内容によって生じた損害等の一切の責任は負いかねますのでご了承下さい。
        </p>
      )
    }
  ]
}
