import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

import { Container } from '@/components/atoms'
import {
  GoTop,
  SEO
} from '@/components/molecules'
import {
  Header,
  Footer
} from '@/components/organisms'
import { useHandleResponsible } from '@/hooks'

import config from '@/config/pages/privacy-policy'
import * as styles from './privacy-policy.module.scss'

type Props = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  thumbnail: any
}

type Section = {
  title: string
  children?: any
  chapters?: Chapter[]
}

type Chapter = {
  title: string
  children: Function
}

const PrivacyPolicy: React.FC<PageProps<Props>> = ({ data }) => {
  const { site, thumbnail } = data
  const meta = site.siteMetadata
  const thumbnailPath = thumbnail.childImageSharp.resize.src
  const location = useLocation()

  const other = {
    title: 'プライバシーポリシー',
    description: 'プライバシーポリシーについて',
    url: location.href
  }

  const { goTopSize } = useHandleResponsible({ breakPoint: 1160 })

  return (
    <>
      <SEO type={"website"} thumbnailPath={thumbnailPath} other={other} />
      <Header siteTitle={meta.title} />
      <div className={styles.root}>
        <Container>
          <div className={styles.root}>
            <article className={styles.article_root}>
              <div className={styles.title_root}>
                <h1 className={styles.title}>プライバシーポリシー</h1>
              </div>
              <div>
                {
                  config.sections.map((section: Section) => (
                    <>
                      <div className={styles.chapter_root}>
                        <h2 className={styles.chapter}>{section.title}</h2>
                      </div>
                      {section.chapters &&
                        section.chapters.map((chapter: Chapter) => (
                          <>
                            <div className={styles.section_root}>
                              <h3 className={styles.section}>{chapter.title}</h3>
                            </div>
                            {chapter.children()}
                          </>
                        ))
                      }
                      {!section.chapters && section.children && (
                        <>{section.children()}</>
                      )}
                    </>
                  ))
                }
              </div>
            </article>
          </div>
          <GoTop size={goTopSize} />
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy

export const PrivacyPolicyPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    thumbnail: file(relativePath: { eq: "blog-ogp-1200x630.png"}) {
      childImageSharp {
        resize(width: 1200, quality: 90) {
          src
        }
      }
    }
  }
`
